import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Particles from 'react-particles-js';

const BlockcuratorsLogo = require('./blockcurators_one_path.svg');

const useStyles = makeStyles(theme => ({
  root: {

  },
}));

class ParticleLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.RenderParticleLogo = this.RenderParticleLogo.bind(this);
  }

  RenderParticleLogo() {
    const classes = useStyles();

    return (
      <div className="particles" id="particleMask">
        <Particles
          height={210}
          params={{
            fps_limit: 25,
            particles: {
              number: {
                value: 200,
                density: {
                  enable: false,
                },
              },
              line_linked: {
                enable: true,
                distance: 30,
                opacity: 0.2,
                width: 2,
                color: this.props.color || '#008080',
              },
              move: {
                speed: 1,
              },
              opacity: {
                anim: {
                  enable: true,
                  opacity_min: 0.05,
                  speed: 2,
                  sync: false,
                },
                value: 0.8,
              },
            },
            polygon: {
              enable: true,
              scale: 1,
              type: 'inline',
              move: {
                radius: 3,
                type: 'path',
              },
              url: BlockcuratorsLogo,
              inline: {
                arrangement: 'equidistant',
              },
              draw: {
                enable: false,
              },
            },
            retina_detect: false,
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: 'bubble',
                },
              },
              modes: {
                bubble: {
                  size: 4,
                  distance: 50,
                },
              },
            },
          }}
        />
      </div>
    );
  }

  render() {
    return (
      <this.RenderParticleLogo />
    );
  }
}

export default ParticleLogo;
